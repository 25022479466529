/* 布局 */
//前台
import Layout from "@views/layout";

const cooperatebusinessadminRouter = {
    path: "/business",
    component: Layout,
    redirect: {
        name: "BUSINESS"
    },
    children: [
        {
        path: "cooperatebusinessadmin/list",
        name: "COOPERATEBUSINESSADMIN",
        meta: {
            title: "企业管理员",
            keepAlive:true
        },
        component: () => import("@views/business/cooperatebusinessadmin/index.vue"),
    },
        ],
};

export default cooperatebusinessadminRouter;
