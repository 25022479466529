/* 布局 */
//前台
import Layout from "@views/layout";

const cooperatebusinessarticleansRouter = {
    path: "/business",
    component: Layout,
    redirect: {
        name: "BUSINESS"
    },
    children: [
        {
            path: "cooperatebusinessarticleans",
            name: "COOPERATEBUSINESSARTICLEANS",
            meta: {
                title: "文档回复管理",
                sort: 1,
            },
            component: () => import("@views/business/cooperatebusinessarticleans/index.vue"),
            redirect: {
                name: "COOPERATEBUSINESSARTICLEANS_LIST",
            },
            children: [
                // {
                //     path: "list",
                //     name: "COOPERATEBUSINESSARTICLEANS_LIST",
                //     meta: {
                //         title: "文档回复管理",
                //         sort: 2,
                //         keepAlive: true
                //     },
                //     component: () => import("@views/business/cooperatebusinessarticleans/list.vue"),
                // },
                // {
                //     path: "add",
                //     name: "COOPERATEBUSINESSARTICLEANS_ADD",
                //     meta: {
                //         title: "新增文档回复表",
                //         parentPath: '/business/cooperatebusinessarticleans/list',
                //         operate: true,
                //     },
                //     component: () => import("@views/business/cooperatebusinessarticleans/form.vue"),
                // },
                // {
                //     path: "edit",
                //     name: "COOPERATEBUSINESSARTICLEANS_EDIT",
                //     meta: {
                //         title: "编辑文档回复表",
                //         parentPath: '/business/cooperatebusinessarticleans/list',
                //         operate: true,
                //     },
                //     component: () => import("@views/business/cooperatebusinessarticleans/form.vue"),
                // },
            ],
        },


    ],
};

export default cooperatebusinessarticleansRouter;
