<style lang="scss">
@import "@assets/styles/variables.scss"; //公共样式变量

body {
  overflow-x: hidden;
}

.el-aside {
  max-height: calc(100vh - 50px);

  .side-bar .side-menu {
    height: 100%;
    overflow: visible;
  }
}

.full.el-main {
  height: 100vh !important;

  .el-table {
    max-height: 100% !important;
  }

  .page-container {
    height: 100vh !important;
  }

  .el-table {
    .el-table__body-wrapper {
      max-height: calc(100vh - 325px) !important;
    }

    .el-table__fixed-body-wrapper {
      max-height: calc(100vh - 335px) !important;
    }
  }
}

.el-container {
  margin-left: 0px !important;
}

.vue-body {
  width: 100%;
  height: 100%;
  overflow: hidden;

  .el-aside {
    width: auto !important;

    .side-bar {
      height: 100%;
      flex-shrink: 0;
    }
  }

  .body-container {
    margin-left: 0px !important;
    height: 100%;

    .el-header {
      padding: 0;
      height: 50px !important;
    }

    .el-main {
      width: 100%;
      padding: 0;
      // height: calc(100%);
      height: calc(100vh - 50px);

      .app-main {
        width: 100%;
        height: 100%;
      }
    }

    .el-footer {
      height: 72px;
      line-height: 72px;
      font-size: 14px;
      color: $color-text-secondary;
      text-align: center;
      background: $body-background;
    }
  }
}
</style>

<template>
  <div id="layout">
    <el-container class="vue-body">
      <el-container class="body-container">
        <el-header id="try-navBar">
          <navbar @handleChangeCollapse="changeCollapse" :isCollapse="isCollapse" :menulist="menulist"
            :active="activeMenu" @changeLeftMenu="changeLeftMenu" />
        </el-header>
        <el-container>
          <el-aside>
            <sidebar :isCollapse="isCollapse" :leftmenu="leftmenu" />
          </el-aside>
          <el-main>
            <app-main @changeMenu="changeMenu" :menuListArr="menulist" />
          </el-main>
        </el-container>
        <!-- <el-footer> {{copyright}} </el-footer> -->
      </el-container>
    </el-container>
  </div>
</template>

<script>
// import autofit from 'autofit.js'
import Navbar from "./components/Navbar";
import AppMain from "./components/AppMain";
import Sidebar from "./components/Sidebar";
import { getGroupItemValue } from "@api/AdminUser";
import { getUserMenu } from "@api/AdminMenu";
import { mapActions } from "vuex";
import { getSocketStatus } from "@api/Message";
import store from "../../../../view/src/store";
export default {
  name: "Layout",
  components: {
    Navbar,
    AppMain,
    Sidebar,
  },
  data() {
    return {
      isCollapse: false,
      copyright: "", // 版权初始化数据
      menulist: [],
      leftmenu: [],
      activeMenu: "",
      wsUrl: "ws://192.168.2.123:9527",
      socket: null,
    };
  },
  computed: {},

  created() {
    // 获取初始化数据
    this.getData();
    this.setDictionaries();
    this.setDicttree();
    this.setDataConfig();
    this.setBusinessData();
    this.setMessagedata();
  },
  mounted() {
    let width = window.innerWidth
    // if (width < 1930) {
    //   autofit.init({
    //     dh: 980,
    //     dw: 1930,
    //     el: "#layout",
    //     resize: true
    //   }, false)
    // }
    this.CreatMenu();
    this.initWebSocket();
  },
  destroyed() {
    // 销毁监听
    this.socket.onclose = this.close;
  },
  methods: {
    ...mapActions("dictionaries", ["setDictionaries"]),
    ...mapActions("dicttree", ["setDicttree"]),
    ...mapActions("configdata", ["setDataConfig"]),
    ...mapActions("businessdata", ["setBusinessData"]),
    ...mapActions("messagedata", ["setMessagedata"]),

    changeCollapse() {
      this.isCollapse = !this.isCollapse;
    },
    msgOpen(msg) {
      if (!msg.type) {
        msg.type = success;
      }
      this.$notify({
        title: msg.title,
        type: msg.type,
        message: msg.text,
      });
    },
    async initWebSocket() {
      let { data: resstatus } = await getSocketStatus();

      if (resstatus.status == 1) {


        if (typeof WebSocket === "undefined") {
          alert("您的浏览器不支持socket");
        } else {
          // 实例化socket
          this.socket = new WebSocket(this.wsUrl);
          // 监听socket连接
          this.socket.onopen = this.open;
          // 监听socket错误信息
          this.socket.onerror = this.error;
          // 监听socket消息
          this.socket.onmessage = this.getMessage;
        }
      }
    },
    open: function () {
      console.log("socket连接成功");
      let uid = sessionStorage.getItem("username");
      console.log(uid);
      let msg = JSON.stringify({
        type: "bind",
        uid: uid,
      });
      this.send(msg);
    },
    error: function () {
      console.log("连接错误");
    },
    getMessage: function (msg) {
      console.log(msg);
      let data = JSON.parse(msg.data);
      //console.log(data);
      switch (data.type) {
        // 服务端ping客户端
        case "ping":
          this.send({
            data: JSON.stringify({
              type: "pong",
            }),
          });
          break;
        // 登录 更新用户列表
        case "text":
          this.msgOpen(data.data);
          console.log(data.data, '消息内容')
          //刷新消息数量        

          //更新消息列表内容         
          this.$store.commit('messagedata/addmessagetodata', { tm_id: data.data.tm_id, tm_title: data.data.title, tm_status: 2, create_time: data.data.create_time, tm_content: data.data.text });

          break;
      }

      // console.log(msg.data)
    },
    // 发送消息给被连接的服务端
    send: function (params) {
      this.socket.send(params);
    },
    close: function () {
      console.log("socket已经关闭");
    },
    async getData() {
      let { data: restitle } = await getGroupItemValue("COPYRIGHT");
      this.copyright = restitle.dgi_value
        ? restitle.dgi_value
        : "Copyright © 2022 四川创企科技有限责任公司 版权所有";
    },
    async CreatMenu() {
      let that = this;
      let { data: res } = await getUserMenu();

      if (!res) return;

      this.menulist = res;
      sessionStorage.setItem("menuArr", JSON.stringify(this.menulist));
      try {
        this.menulist.forEach(function (child, key) {
          child.children.forEach(function (item, index) {
            if (item.children.length > 0) {
              item.children.forEach(function (item2, index2) {
                if (item2.amenu_address == that.$route.fullPath) {
                  that.activeMenu = that.menulist[key].amenu_id + "";
                  that.leftmenu = child.children;
                  throw new Error("1");
                }
              });
            } else {
              if (item.amenu_address == that.$route.fullPath) {
                that.activeMenu = that.menulist[key].amenu_id + "";
                that.leftmenu = child.children;
                throw new Error("1");
              }
            }
          });
        });
      } catch (e) {
        console.log(e);
      }
    },
    changeLeftMenu(e) {
      this.leftmenu = e;
    },
    changeMenu(e, parentPath) {
      let that = this;
      try {
        this.menulist.forEach(function (child, key) {
          child.children.forEach(function (item, index) {
            if (item.children.length > 0) {
              item.children.forEach(function (item2, index2) {
                if (
                  item2.amenu_address == e ||
                  item2.amenu_address == parentPath
                ) {
                  that.activeMenu = that.menulist[key].amenu_id + "";
                  that.leftmenu = child.children;
                  throw new Error("1");
                }
              });
            } else {
              if (item.amenu_address == e || item.amenu_address == parentPath) {
                that.activeMenu = that.menulist[key].amenu_id + "";
                that.leftmenu = child.children;
                throw new Error("1");
              }
            }
          });
        });
      } catch (e) { }
    },
  },
};
</script>
