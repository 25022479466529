/* 布局 */
//前台
import Layout from "@views/layout";

const cooperatebusinessRouter = {
    path: "/business",
    component: Layout,
    redirect: {
        name: "BUSINESS"
    },
    children: [
        {
            path: "cooperatebusiness",
            name: "COOPERATEBUSINESS",
            meta: {
                title: "企业会员管理",
                sort: 1,
            },
            component: () => import("@views/business/cooperatebusiness/index.vue"),
            redirect: {
                name: "COOPERATEBUSINESS_LIST",
            },
            children: [
                {
                    path: "list",
                    name: "COOPERATEBUSINESS_LIST",
                    meta: {
                        title: "企业会员管理",
                        sort: 2,
                        keepAlive: true
                    },
                    component: () => import("@views/business/cooperatebusiness/list.vue"),
                },
                {
                    path: "add",
                    name: "COOPERATEBUSINESS_ADD",
                    meta: {
                        title: "新增合作-企业",
                        parentPath: '/business/cooperatebusiness/list',
                        operate: true,
                    },
                    component: () => import("@views/business/cooperatebusiness/form.vue"),
                },
                {
                    path: "edit",
                    name: "COOPERATEBUSINESS_EDIT",
                    meta: {
                        title: "编辑合作-企业",
                        parentPath: '/business/cooperatebusiness/list',
                        operate: true,
                    },
                    component: () => import("@views/business/cooperatebusiness/form.vue"),
                },



                // 合作企业文档 -start
                {
                    path: "listarticle",
                    name: "COOPERATEBUSINESSARTICLE_LIST",
                    meta: {
                        title: "合作企业文档管理",
                        parentPath: '/business/cooperatebusiness/list',
                        sort: 2,
                        keepAlive: true
                    },
                    component: () => import("@views/business/cooperatebusinessarticle/list.vue"),
                },
                {
                    path: "addarticle",
                    name: "COOPERATEBUSINESSARTICLE_ADD",
                    meta: {
                        title: "新增合作-企业-文档",
                        parentPath: '/business/cooperatebusiness/list',
                        operate: true,
                    },
                    component: () => import("@views/business/cooperatebusinessarticle/form.vue"),
                },
                {
                    path: "editarticle",
                    name: "COOPERATEBUSINESSARTICLE_EDIT",
                    meta: {
                        title: "编辑合作-企业-文档",
                        parentPath: '/business/cooperatebusiness/list',
                        operate: true,
                    },
                    component: () => import("@views/business/cooperatebusinessarticle/form.vue"),
                },

                // 合作企业文档 -end

                // 文档回复 -start
                {
                    path: "listans",
                    name: "COOPERATEBUSINESSARTICLEANS_LIST",
                    meta: {
                        title: "文档回复管理",
                        parentPath: '/business/cooperatebusiness/list',
                        operate: true,
                    },
                    component: () => import("@views/business/cooperatebusinessarticleans/list.vue"),
                },
                {
                    path: "addans",
                    name: "COOPERATEBUSINESSARTICLEANS_ADD",
                    meta: {
                        title: "新增文档回复",
                        parentPath: '/business/cooperatebusiness/list',
                        operate: true,
                    },
                    component: () => import("@views/business/cooperatebusinessarticleans/form.vue"),
                },
                {
                    path: "editans",
                    name: "COOPERATEBUSINESSARTICLEANS_EDIT",
                    meta: {
                        title: "编辑文档回复",
                        parentPath: '/business/cooperatebusiness/list',
                        operate: true,
                    },
                    component: () => import("@views/business/cooperatebusinessarticleans/form.vue"),
                },
                // 文档回复 -end
                
                // 企业管理员 -start
                {
                    path: "cooperatebusinessadmin/list",
                    name: "COOPERATEBUSINESSADMIN",
                    meta: {
                        title: "企业管理员",
                        parentPath: '/business/cooperatebusiness/list',
                        operate: true
                    },
                    component: () => import("@views/business/cooperatebusinessadmin/index.vue"),
                },
                // 企业管理员 -end

                // 栏目管理 -start
                {
                    path: "cooperatebusinesscolumn/list",
                    name: "COOPERATEBUSINESSCOLUMN",
                    meta: {
                        title: "企业栏目",
                        parentPath: '/business/cooperatebusiness/list',
                        operate: true
                    },
                    component: () => import("@views/business/cooperatebusinesscolumn/index.vue"),
                },
                // 栏目管理 -end

                // 目录管理 -start
                {
                    path: "listdirectory",
                    name: "COOPERATEBUSINESSCOLUMNDIRECTORY_LIST",
                    meta: {
                        title: "企业栏目目录",
                        parentPath: '/business/cooperatebusiness/list',
                        operate: true
                    },
                    component: () => import("@views/business/cooperatebusinesscolumndirectory/list.vue"),
                },
                {
                    path: "adddirectory",
                    name: "COOPERATEBUSINESSCOLUMNDIRECTORY_ADD",
                    meta: {
                        title: "新增合作-企业栏目目录",
                        parentPath: '/business/cooperatebusiness/list',
                        operate: true,
                    },
                    component: () => import("@views/business/cooperatebusinesscolumndirectory/form.vue"),
                },
                {
                    path: "editdirectory",
                    name: "COOPERATEBUSINESSCOLUMNDIRECTORY_EDIT",
                    meta: {
                        title: "编辑合作-企业栏目目录",
                        parentPath: '/business/cooperatebusiness/list',
                        operate: true,
                    },
                    component: () => import("@views/business/cooperatebusinesscolumndirectory/form.vue"),
                },
                // 目录管理 -end
                // 快速链接 -start
                {
                    path: "listlink",
                    name: "COOPERATEBUSINESSCOLUMNLINK_LIST",
                    meta: {
                        title: "合作-企业-栏目-链接管理",
                        parentPath: '/business/cooperatebusiness/list',
                        operate: true
                    },
                    component: () => import("@views/business/cooperatebusinesscolumnlink/list.vue"),
                },
                {
                    path: "addlink",
                    name: "COOPERATEBUSINESSCOLUMNLINK_ADD",
                    meta: {
                        title: "新增合作-企业-栏目-链接",
                        parentPath: '/business/cooperatebusiness/list',
                        operate: true,
                    },
                    component: () => import("@views/business/cooperatebusinesscolumnlink/form.vue"),
                },
                {
                    path: "editlink",
                    name: "COOPERATEBUSINESSCOLUMNLINK_EDIT",
                    meta: {
                        title: "编辑合作-企业-栏目-链接",
                        parentPath: '/business/cooperatebusiness/list',
                        operate: true,
                    },
                    component: () => import("@views/business/cooperatebusinesscolumnlink/form.vue"),
                },
                // 快速链接 -end

            ],
        },


    ],
};

export default cooperatebusinessRouter;
