/* 布局 */
//前台
import Layout from "@views/layout";

const activityindsecRouter = {
    path: "/business",
    component: Layout,
    redirect: {
        name: "BUSINESS"
    },
    children: [
            {
            path: "activityindsec",
            name: "ACTIVITYINDSEC",
            meta: {
                title: "行业领域管理",
                sort: 1,
            },
            component: () => import("@views/business/activityindsec/index.vue"),
            redirect: {
                name: "ACTIVITYINDSEC_LIST",
            },
            children: [
                {
                    path: "list",
                    name: "ACTIVITYINDSEC_LIST",
                    meta: {
                        title: "行业领域管理",
                        sort: 2,
                        keepAlive:true
                    },
                    component: () => import("@views/business/activityindsec/list.vue"),
                },
                                {
                    path: "add",
                    name: "ACTIVITYINDSEC_ADD",
                    meta: {
                        title: "新增活动行业领域表",
                        parentPath: '/business/activityindsec/list',
                        operate: true,
                    },
                    component: () => import("@views/business/activityindsec/form.vue"),
                },
                                                {
                    path: "edit",
                    name: "ACTIVITYINDSEC_EDIT",
                    meta: {
                        title: "编辑活动行业领域表",
                        parentPath: '/business/activityindsec/list',
                        operate: true,
                    },
                    component: () => import("@views/business/activityindsec/form.vue"),
                },
                                        ],
    },


        ],
};

export default activityindsecRouter;
