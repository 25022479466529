<template>
  <div class="images-list" v-if="!isUseOss">
    <el-upload class="upload-demo" ref="UplaodFile" :action="uploadUrl" :data="uploadData"
      :before-upload="handleBeforeUpload" :on-success="handleSuccess" :on-error="handleUploadError"
      :on-remove="handleRemove" :on-exceed="handleExceed" :file-list="fileList" :multiple="upOption.fileLimit > 1"
      :headers="paramsData" :limit="upOption.fileLimit" :list-type="listType" :drag="listType === 'text'"
      :disabled="disabled">
      <i v-if="listType === 'picture-card'" class="el-icon-plus"></i>
      <i v-else-if="listType === 'text'" class="el-icon-upload"></i>
      <el-button v-else size="small" type="primary">点击上传</el-button>
      <div v-if="listType === 'text'" class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
      <div v-if="showTip" slot="tip" class="el-upload__tip">
        只能上传{{ fileTypeName || "jpg/png" }}文件，且不超过 {{ upOption.fileSize }}MB
      </div>
    </el-upload>
  </div>
  <div v-else v-loading="loading" :element-loading-text="loadingText">
    <!-- :show-file-list="showFile"  :on-preview="handlePreview" -->
    <el-upload class="upload-demo" ref="uploadOss" action="string" :data="uploadData"
      :before-upload="handleBeforeUpload" :on-success="handleSuccess" :on-error="handleUploadError"
      :on-remove="handleOssRemove" :on-exceed="handleExceed" :file-list="fileList" :multiple="upOption.fileLimit > 1"
      :limit="upOption.fileLimit" :list-type="listType" :drag="listType === 'text'" :disabled="disabled"
      :http-request="handleUploadFile">
      <i v-if="listType === 'picture-card'" class="el-icon-plus"></i>
      <i v-else-if="listType === 'text'" class="el-icon-upload"></i>
      <el-button v-else size="small" type="primary">点击上传</el-button>
      <div v-if="listType === 'text'" class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
      <div v-if="showTip" slot="tip" class="el-upload__tip">
        只能上传{{ fileTypeName || "jpg/png" }}文件，且不超过 {{ upOption.fileSize }}MB
      </div>
    </el-upload>
  </div>
</template>
<script>
import { getGroupData } from "@api/DataGroup";
import { UPLOAD_IMG_URL, UPLOAD_FILE_URL, UPLOAD_FILE_SLICE, UPLOAD_FILE_INPORT } from "@config";

const OSS = require('ali-oss');
import { getaliyunoss } from "@api/common";
import { addAttachment } from "@api/Attachment";

export default {
  name: "TryUpload",
  props: {
    // 值
    value: [String, Object, Array],
    // 大小限制(MB)
    fileSize: {
      type: Number,
      default: 5,
    },
    // 文件类型, 例如["doc", "xls", "ppt", "txt", "pdf"]
    fileType: {
      type: Array,
      default: () => ["png", "jpg", "jpeg", "gif"],
    },
    uploadData: {
      type: Object,
      default: () => {
      },
    },
    //上传文件类型
    upType: {
      type: String,
      default: 'IMG'
    },
    // 是否显示提示
    isShowTip: {
      type: Boolean,
      default: true,
    },
    // 最大允许上传个数
    fileLimit: {
      type: Number,
      default: 9,
    },
    //是否为导入组件
    isImport: {
      type: Boolean,
      default: false
    },
    disabled: {//禁用属性
      type: Boolean,
      default: false
    },  // 是否使用Oss直传
    fileExt: { //单独设置 仅允许的文件格式
      type: Array,
      default: () => [],
    },
    // 是否使用Oss直传
    isUseOss: {
      type: Boolean,
      default: false
    },
    // 需要二次上传oss的文件类型
    putTwoOssFileType: {
      type: Array,
      default: () => ["png", "PNG", "jpg", "JPG", "jpeg", "JPEG", "gif", "GIF"],
    },
  },
  data() {
    return {
      uploadUrl: UPLOAD_IMG_URL, // 上传的图片服务器地址
      paramsData: {
        "accessToken": sessionStorage.getItem("token"),
        "charset": "utf-8"
      }, // 上传携带的参数，看需求要不要
      fileList: [],
      tempFileList: [], // 因为 fileList为只读属性，所以用了一个中间变量来进行数据改变的交互。
      upOption: {
        "fileSize": this.fileSize,
        "fileType": this.fileType,
        "fileLimit": this.fileLimit,
      },
      // 文件列表类型 text/picture/picture-card
      listType: 'picture',

      ossUploadData: [], // oss上传文件
      upImglength: 0,
      upImgOklength: 0,

      loading: false,

      loadingText: '上传中...'
    };
  },
  watch: {
    value: {
      handler: function (newVal, oldVa) {
        if (newVal != "" && newVal != undefined) {
          let imgs = JSON.parse(newVal);
          this.fileList = imgs.map((a, index) => {
            return {
              uid: a.att_id,
              name: a.att_title,
              url: a.att_url,
            };
          });
          this.ossUploadData = imgs.map((a, index) => {
            return {
              att_id: a.att_id,
              att_title: a.att_title,
              att_url: a.att_url,
            };
          });
        }
        else this.fileList = [];
      },
      immediate: true,
      deep: true,
    },
    fileExt: {
      handler: function (newVal, oldVal) {
        this.fileExt = newVal;
        if (this.fileExt.length > 0) {
          this.upOption.fileType = this.fileExt;
        }

      }
    },
    upType: {
      handler: function (newVal, oldVal) {
        this.upType = newVal;

        this.upOption.fileLimit = this.upType == "FILE" ? 1 : this.fileLimit;

        //从多文件 切回单文件， 仅保留  最新的一个文件
        if (this.upOption.fileLimit == 1 && this.ossUploadData.length > 1) {
          this.ossUploadData = [this.ossUploadData[this.ossUploadData.length - 1]];

          // 回传回 调用组件处
          const tempfileData = JSON.stringify(this.ossUploadData);
          this.$emit("input", this.ossUploadData.length > 0 ? tempfileData : null);
        }
        console.log(222222222222222);
        console.log(this.upType);
        console.log(this.fileLimit);
        console.log(this.upOption.fileLimit);
        console.log(this.ossUploadData);
      }
    }

  },
  computed: {
    // 是否显示提示
    showTip() {
      return this.isShowTip && (this.upOption.fileType || this.upOption.fileSize);
    },
    fileTypeName() {
      let typeName = "";
      this.upOption.fileType.forEach((item) => {
        typeName += `${item}，`;
      });
      return typeName;
    },
    fileAccept() {
      let fileAccept = "";
      this.upOption.fileType.forEach((element) => {
        fileAccept += `.${element},`;
      });
      return fileAccept;
    },
  },
  created() {
    this.initData();
  },
  methods: {

    /*初始化数据*/
    async initData() {

      if (this.isImport) {
        this.upOption.fileLimit = this.upType == "FILE" ? 1 : this.upOption.fileLimit;
        this.uploadUrl = UPLOAD_FILE_INPORT;
        this.upOption = {
          "fileSize": 5,
          "fileType": ['xls', 'xlsx'],
          "fileLimit": 1,
        };
        this.listType = "text";
      } else {
        if (this.upType == "IMG" || this.upType == "IMGARR") {
          let { data: resImg } = await getGroupData("IMAGECONFIG");
          this.upOption = resImg;
          this.upOption.fileLimit = this.upType == "IMG" ? 1 : this.upOption.fileLimit;
          this.listType = this.upType == "IMG" ? "picture" : "picture-card";

        } else {
          let { data: resFile } = await getGroupData("FILECONFIG");
          this.upOption = resFile;
          if (this.fileExt.length > 0) {
            this.upOption.fileType = this.fileExt;
          }
          this.upOption.fileLimit = this.upType == "FILE" ? 1 : this.upOption.fileLimit;
          this.uploadUrl = UPLOAD_FILE_URL;
          this.listType = "text";
        }
      }
    },

    // OSS自定义上传
    async handleUploadFile(option) {
      this.loading = true;
      this.upImglength++
      console.log("options", option);
      if (this.handleBeforeUpload(option.file)) {
        //获取上传后的url
        const _name = this.getDate() + '/' + this.generateCustomKey() + '.' + option.file.name.split('.')[option.file.name.split('.').length - 1];
        /** 上传第二个oss */
        // 提取文件扩展名
        let fileExtension = (option.file.name).slice(option.file.name.lastIndexOf(".") + 1);
        // 检查文件类型是否符合要求
        const isTypeOk = this.putTwoOssFileType.some((type) => {
          // 判断文件扩展名是否包含允许的类型之一
          if (fileExtension.indexOf(type) > -1) return true;
          if (fileExtension && fileExtension.indexOf(type) > -1) return true;
          return false;
        });
        // 如果文件类型符合要求且文件存在，则二次上传文件
        if (isTypeOk && option.file) {
          this.putTwoOss(_name, option.file);
        }
        /** 上传第一个oss */
        this.putOss(_name, option.file).then(async (res) => {
          if (res) {
            this.getOssUrl(res.name).then(async (rel) => {
              const url = new URL(rel);
              const requestUrls = url.origin + url.pathname;
              console.log("putOneOss", requestUrls);
              let tempItem = {
                att_title: option.file.name,
                att_url: requestUrls
              };
              this.loading = false;
              /** 获取入库文件attid */
              let att_data = await this.addAttachment(tempItem);
              tempItem.att_id = att_data.att_id;
              tempItem.att_url = att_data.att_url;

              if (this.upOption.fileLimit > 1) {
                //多文件
                this.ossUploadData.push(tempItem);
              } else {
                //单文件
                this.ossUploadData = [tempItem];
              }


              this.upImgOklength++
              if (this.upImglength == this.upImgOklength) {
                const tempfileData = JSON.stringify(this.ossUploadData);
                this.$emit("input", this.ossUploadData.length > 0 ? tempfileData : null);
              }
            })
          } else {
            this.$message.error("前端直传OSS失败");
            return false;
          }
        })
      }
    },
    /** 上传第二个oss */
    async putTwoOss(filename, file) {
      let res = await getaliyunoss();
      let ossConfig = res.data;
      const client = new OSS({
        region: ossConfig.region2 ? ossConfig.region2 : "",
        accessKeyId: ossConfig.accessKeyId,
        accessKeySecret: ossConfig.accessKeySecret,
        stsToken: ossConfig.securityToken,
        bucket: ossConfig.bucket2 ? ossConfig.bucket2 : "", // 第二个oss的bucket自行替换
      });
      let fileurl = ossConfig.img_path + filename;
      const headers = {
        // 指定该Object被下载时的内容编码格式。
        "Content-Encoding": "utf-8",
        'Access-Control-Allow-Origin': '*',
      };
      const options = {
        headers: headers,
        // 获取分片上传进度、断点和返回值。
        progress: (p, cpt, res) => {
          console.log(p);
          // this.loadingText = (p * 100).toFixed(0) + "%";

        },
        // 设置并发上传的分片数量。
        parallel: 4,
        // 设置分片大小。默认值为1 MB，最小值为100 KB。
        partSize: 1024 * 1024,
        // 自定义元数据，通过HeadObject接口可以获取Object的元数据。
        meta: { year: 2020, people: "test" },
        //mime: "text/plain",
        timeout: 600000,//设置超时时间
      };
      const result = await client.multipartUpload(fileurl, file, options);
      console.log("putTwoOss", result);
      return true;
    },
    /** 文件入附件库 */
    async addAttachment(data) {
      data.att_storage = 2;
      const { data: resdata } = await addAttachment(data);
      return resdata;
    },
    /** 提交数据到oss获取oss返回数据 */
    async putOss(fileName, file) {
      console.log()
      try {
        let res = await getaliyunoss();
        let ossConfig = res.data;
        const client = new OSS({
          // yourRegion填写Bucket所在地域。以华东1（杭州）为例，yourRegion填写为oss-cn-hangzhou。
          region: ossConfig.region,
          accessKeyId: ossConfig.accessKeyId,
          accessKeySecret: ossConfig.accessKeySecret,
          stsToken: ossConfig.securityToken,
          bucket: ossConfig.bucket,
          // timeout: 600000,
          // secure: false,
          // refreshSTSTokenInterval: 3000000,
          // refreshSTSToken: getaliyunoss().then(res => {
          //   if (res.status === 200) {
          //     // console.log('成功刷新oss token',res.data);
          //     ossConfig.accessKeyId = res.data.accessKeyId;
          //     ossConfig.accessKeySecret = res.data.accessKeySecret;
          //     ossConfig.securityToken = res.data.securityToken;
          //   }
          // })
        });
        let fileurl = ossConfig.img_path + fileName;
        const headers = {
          // 指定该Object被下载时的网页缓存行为。
          // "Cache-Control": "no-cache",
          // 指定该Object被下载时的名称。
          // "Content-Disposition": "example.txt",
          // 指定该Object被下载时的内容编码格式。
          "Content-Encoding": "utf-8",
          // 指定过期时间，单位为毫秒。
          // Expires: "1000",
          // 指定Object的存储类型。
          // "x-oss-storage-class": "Standard",
          // 指定Object标签，可同时设置多个标签。
          // "x-oss-tagging": "Tag1=1&Tag2=2",
          // 指定初始化分片上传时是否覆盖同名Object。此处设置为true，表示禁止覆盖同名Object。
          // "x-oss-forbid-overwrite": "true",

          'Access-Control-Allow-Origin': '*',
        };
        const options = {
          headers: headers,
          // 获取分片上传进度、断点和返回值。
          progress: (p, cpt, res) => {
            // console.log(p);
            this.loadingText = '上传中... ' + (p * 100).toFixed(0) + "%";


          },
          // 设置并发上传的分片数量。
          parallel: 4,
          // 设置分片大小。默认值为1 MB，最小值为100 KB。
          partSize: 1024 * 1024,
          // 自定义元数据，通过HeadObject接口可以获取Object的元数据。
          meta: { year: 2020, people: "test" },
          // mime: "text/plain",
          timeout: 600000,//设置超时时间
        };
        const result = await client.multipartUpload(fileurl, file, options);

        return result;

      } catch (e) {
        console.log(e);
      }
    },
    async getOssUrl(name) {
      try {
        let res = await getaliyunoss();
        let ossConfig = res.data;
        const client = new OSS({
          region: ossConfig.region,
          accessKeyId: ossConfig.accessKeyId,
          accessKeySecret: ossConfig.accessKeySecret,
          stsToken: ossConfig.securityToken,
          bucket: ossConfig.bucket,
        });
        const result = await client.signatureUrl(name);
        return result;
      } catch (e) {
        console.log(e);
      }
    },

    // 文件列表移除文件时的钩子
    handleOssRemove(file, fileList) {
      this.changeOssFileList(fileList);
    },
    // 文件列表改变的时候，更新组件的v-model的数据
    changeOssFileList(fileList) {
      let tempFileList = [];
      fileList.map((item) => {
        let tempItem = {};
        this.ossUploadData.map((val) => {
          if (item.uid == val.att_id) {
            tempItem = {
              att_id: item.uid,
              att_title: item.name,
              att_url: val.att_url
            };
            tempFileList.push(tempItem);
          }
        })
      });
      this.ossUploadData = tempFileList;
      const tempfileData = JSON.stringify(this.ossUploadData);

      this.$emit("input", this.ossUploadData.length > 0 ? tempfileData : null);
    },

    // 上传前校检格式和大小
    handleBeforeUpload(file) {
      // 校检文件类型
      if (this.upOption.fileType && file) {
        let fileExtension = "";
        if (file.name.lastIndexOf(".") > -1) {
          fileExtension = file.name.slice(file.name.lastIndexOf(".") + 1);
        }
        const isTypeOk = this.upOption.fileType.some((type) => {
          if (file.type.indexOf(type) > -1) return true;
          if (fileExtension && fileExtension.indexOf(type) > -1) return true;
          return false;
        });

        if (!isTypeOk && file) {
          this.$message.error(
            `文件格式不正确, 请上传${this.upOption.fileType.join("/")}格式文件!`
          );
          return false;
        }
      }
      // 校检文件大小
      if (this.upOption.fileSize && file) {
        const isLt = file.size / 1024 / 1024 < this.upOption.fileSize;
        if (!isLt) {
          this.$message.error(`上传文件大小不能超过 ${this.upOption.fileSize} MB!`);
          return false;
        }
      }
      return true;
    },
    handleUploadError(err) {
      this.$message.error("上传失败, 请重试");
      console.log(err);
    },
    // 文件个数超出
    handleExceed(files, fileList) {
      if (this.upOption.fileLimit == 1) {
        if (!this.isUseOss) {
          this.$refs.UplaodFile.clearFiles();//删除所有上传的文件
          this.$refs.UplaodFile.handleStart(files[0]);//handleStart()指的是手动选择文件
          this.$refs.UplaodFile.submit();//手动上传
        } else {
          this.$refs.uploadOss.clearFiles();//删除所有上传的文件
          this.$refs.uploadOss.handleStart(files[0]);//handleStart()指的是手动选择文件
          this.$refs.uploadOss.submit();//手动上传
        }
      } else {
        this.$message.error(`超出上传文件个数限制，单次上传限制：` + this.upOption.fileLimit + "个文件以内！");
      }
    },


    // 文件列表移除文件时的钩子
    handleRemove(file, fileList) {
      this.changeFileList(fileList);
    },
    // 文件列表改变的时候，更新组件的v-model的数据
    changeFileList(fileList) {
      const tempFileList = fileList.map((item) => {
        let tempItem = {};
        if (item.response && item.response.status === 200) {
          tempItem = {
            att_id: item.response.data.att_id,
            att_title: item.response.data.original_name,
            att_url: item.response.data.address
          };
        } else {
          tempItem = {
            att_id: item.uid,
            att_title: item.name,
            att_url: item.url
          };
        }
        return tempItem;
      });
      const tempfileData = JSON.stringify(tempFileList);
      // console.log("tempfileData",tempfileData);

      this.$emit("input", tempFileList.length > 0 ? tempfileData : null);
    },


    handleSuccess(res, file, fileList) { // 图片上传服务器后的回调
      if (res) {
        if (res.status === 200) {

          let index = 0;
          fileList.map((item) => {
            if ((item.response && item.response.status === 200) || item.status == "success") {
              index++;

            }
          });
          if (fileList.length == index) {
            this.changeFileList(fileList);
          }
        } else {
          this.delShowFile(file, fileList);
          this.$message.error(file.name + '上传失败!');
        }
      }
    },

    // 把上传失败的从list中删除
    delShowFile(file, fileList) {
      let index = fileList.findIndex(item => {
        return item.name === file.name;
      });
      if (index !== -1) {
        fileList.splice(index, 1);
      }
    },


    // 获取当前年月日
    getDate() {
      const date = new Date()
      var year = date.getFullYear()
      var month = date.getMonth() + 1
      var day = date.getDate()
      month = month > 9 ? month : '0' + month
      day = day < 10 ? '0' + day : day
      var today = year + month + day
      return today
    },

    /**
     * 根据时间生成唯一键
     */
    generateCustomKey() {
      const datePart = new Date().toISOString().replace(/[-:.TZ]/g, '').slice(0, 14); // 获取当前时间的特定格式
      const randomPart = Math.random().toString(36).substring(2, 10); // 生成随机字符串
      return datePart + randomPart;
    },

  },
};
</script>
<style lang="scss" scoped>
.images-list {
  border: 1px dashed #d5d5d5;
  padding: 10px;
  border-radius: 4px;
  background: #fff;
}
</style>