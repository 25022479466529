<template>
  <div>

  <el-select class="try_select_tree" :value="valueTitle" :size="size" :clearable="clearable"  @clear="clearHandle"
    :disabled="disabled" :noSelf="noSelf">
    <el-input class="selectInput" :size="size" :placeholder="placeholder" v-model="filterText">
    </el-input>
   
    <el-option :value="valueTitle" :label="valueTitle" class="options">
      <el-tree id="tree-option"  ref="selectTree" :accordion="accordion"
        :data="optionsData.length ? optionsData : remoteData" :props="props" :node-key="props.value"
        :default-expanded-keys="defaultExpandedKey" :filter-node-method="filterNode" @node-click="handleNodeClick">
      </el-tree>
    </el-option>
  </el-select>
</div>
</template>

<script>
import API from "@utils/request";
import { getTreeDepKeyVal, getDepTreeByUserId } from "@api/AdminDepartment";
import { getDataDicttreeKeyVal } from "@api/DataDicttree";
export default {
  name: 'TrySelectTree',
  props: {
    url: { // 接口地址
      type: String,
      default: undefined
    },
    isEdit: { // 是否编辑模式
      type: Boolean,
      default: false
    },
    disValue: {
      type: [String, Number],
      default: ""
    },
    /* 配置项 */
    props: {
      type: Object,
      default: () => {
        return {
          value: 'value', // ID字段名
          label: 'label', // 显示名称
          children: 'children' // 子级字段名
        }
      }
    },
    /* 选项列表数据(树形结构的对象数组) */
    options: {
      type: Array,
      default: () => { return [] }
    },
    /* 初始值 */
    value: {
      type: [Number, String, Array],
      default: () => { return null }
    },
    size: { // medium/small/mini
      type: String,
      default: 'medium'
    },
    dictName: { // 树形字典  Dep:组织架构   DepAuth:带权限组织架构
      type: String,
      default: undefined
    },
    /* 可清空选项 */
    clearable: {
      type: Boolean,
      default: () => { return true }
    },
    /* 自动收起 */
    accordion: {
      type: Boolean,
      default: () => { return false }
    },
    placeholder: {
      type: String,
      default: () => { return '检索关键字' }
    },
    parameData: {//Url带入参数
      type: [String, Number, Array, Object],
      default: undefined
    },
    disabled: {//禁用属性
      type: Boolean,
      default: false
    },
    noSelf: {//去除当前层级及一下层级
      type: Number,
      default: 0
    },
    isLeafNode: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      keyIndex:1,//key
      filterText: '',
      valueId: this.value, // 初始值
      valueTitle: '',
      defaultExpandedKey: [],
      remoteData: [],
      optionsData: this.options ? this.options : [],
    }
  },
  watch: {
    value(n, o) {
      this.valueId = n;
      this.initHandle();
    },
    filterText(val) {
      this.$refs.selectTree.filter(val);
    },
    options(n, o) {
     
      this.optionsData = n;
      if (n) {
        this.$nextTick(() => {
          this.initHandle();
          if (this.disValue != "" && this.disValue != null && this.disValue != undefined && this.disValue != 0) {
            var node = this.$refs.selectTree.getNode(this.disValue);
            this.$refs.selectTree.remove(node);
          }
        });
      }
    }
  },
  mounted() {

  },
  created() {

    this.requestData();
  },
  methods: {
    // 请求数据
    async requestData() {
      if (this.dictName) {
        if (this.dictName == "Dep") {
          this.loading = true;
          let { data: res } = await getTreeDepKeyVal({ 'noSelf': this.noSelf });
          this.optionsData = res;

          this.loading = false;
        } else if (this.dictName == "DepAuth") {
          this.loading = true;
          let { data: res } = await getDepTreeByUserId();
          this.optionsData = res;
          this.loading = false;
        } else {

          let { data: res } = await getDataDicttreeKeyVal(this.dictName);
          this.optionsData = res;
         
        }
        this.$nextTick(() => {
         
          this.initHandle();
        });
      } else if (this.url) {

        try {
          let { data: res } = await API.post(this.url, eval('(' + this.parameData + ')'));
          this.optionsData = res;
          this.$nextTick(() => {
            this.initHandle();
          });
        } catch (error) {
          return error;
        }
      } else {
        this.$nextTick(() => {
          this.initHandle();
        });
      }
    },
    // 初始化值
    initHandle() {
      if (this.valueId) {

        var node = this.$refs.selectTree.getNode(this.valueId);
        if (node) {
          this.valueTitle = node.data[this.props.label]; // 初始化显示
          this.$refs.selectTree.setCurrentKey(this.valueId); // 设置默认选中
          this.defaultExpandedKey = [this.valueId]; // 设置默认展开
        }
      } else {
        this.clearHandle();
      }

      this.initScroll();

    },
    // 初始化滚动条
    initScroll() {
      this.$nextTick(() => {
        const scrollWrap = document.querySelectorAll('.try_select_tree .el-scrollbar .el-select-dropdown__wrap')[0]
        const scrollBar = document.querySelectorAll('.try_select_tree .el-scrollbar .el-scrollbar__bar')
        if (scrollWrap) {
          scrollWrap.style.cssText = 'margin: 0px; max-height: none; overflow: hidden;'
        }
        scrollBar.forEach(ele => {
          ele.style.width = 0
        })
      })
    },
    // 切换选项
    handleNodeClick(node) {

      if (!this.isLeafNode || !node.children || node.children.length === 0) {
        this.valueTitle = node[this.props.label];
        this.valueId = node[this.props.value];
        this.$emit('input', this.valueId);
        this.defaultExpandedKey = [];
        this.$emit('change', this.valueId, node);
      }

    },
    // 清除选中
    clearHandle() {
      this.valueTitle = '';
      this.valueId = null;
      this.defaultExpandedKey = [];
      this.clearSelected();
      this.$emit('input', null);
      this.$emit('change', null);
    },
    /* 清空选中样式 */
    clearSelected() {
      const allNode = document.querySelectorAll('#tree-option .el-tree-node');
      allNode.forEach((element) => element.classList.remove('is-current'));
    },
    filterNode(value, data) {
      if (!value) return true;
      return data[this.props.label].indexOf(value) !== -1;
    },
    getCurrentData() {
      var node = this.$refs.selectTree.getNode(this.valueId)
      if (node) {
        return node.data
      } else {
        return undefined
      }
    },
    getData() {
      return this.remoteData.length ? this.remoteData : []
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.el-scrollbar .el-scrollbar__view .el-select-dropdown__item {
  height: auto;
  max-height: 274px;
  padding: 0;
  overflow: hidden;
  overflow-y: auto;
}


.el-select-dropdown__item.selected {
  font-weight: normal;
  
}


ul li>>>.el-tree .el-tree-node__content {
  height: auto;
  padding: 0 20px;
}

.el-tree-node__label {
  font-weight: normal;
}

.el-tree>>>.is-current .el-tree-node__label {
  color: #409EFF;
  font-weight: 700;
}

.el-tree>>>.is-current .el-tree-node__children .el-tree-node__label {
  color: #606266;
  font-weight: normal;
}

.selectInput {
  padding: 0 5px;
  box-sizing: border-box;
}



</style>
