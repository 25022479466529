/* 布局 */
//前台
import Layout from "@views/layout";

const activitysigninRouter = {
    path: "/business",
    component: Layout,
    redirect: {
        name: "BUSINESS"
    },
    children: [
            {
            path: "activitysignin",
            name: "ACTIVITYSIGNIN",
            meta: {
                title: "活动签到管理",
                sort: 1,
            },
            component: () => import("@views/business/activitysignin/index.vue"),
            redirect: {
                name: "ACTIVITYSIGNIN_LIST",
            },
            children: [
                {
                    path: "list",
                    name: "ACTIVITYSIGNIN_LIST",
                    meta: {
                        title: "活动签到管理",
                        sort: 2,
                        keepAlive:true
                    },
                    component: () => import("@views/business/activitysignin/list.vue"),
                },
                                                        ],
    },


        ],
};

export default activitysigninRouter;
