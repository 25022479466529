import Vue from "vue";
import "normalize.css/normalize.css"; // 替代CSS重置
import "@utils/element-ues"; // 按需引入ElementUI
import "@assets/styles/public.scss"; // 全局引用样式
import App from "./App.vue";
import router from "./router";
import store from "./store";

import './permission' // 权限控制

import _ from "lodash"; // JavaScript 实用工具库

import * as filters from "@/filters"; // 全局过滤器

import * as directives from "./directives"; // 全局过指令

//ueditor
import config from "../lib/ueditor/ueditor.config.js";
import "../lib/ueditor/ueditor.all.min.js";
import "../lib/ueditor/lang/zh-cn/zh-cn.js";

//print
import print from "print-js";


Vue.prototype.$bus = new Vue()



// 处理自定义组件全局注册
const files = require.context('./components/try', true, /\.vue$/)
files.keys().forEach((routerPath) => {
  const componentName = routerPath.replace(/^\.\/(.*)\/index\.\w+$/, '$1')
  if (componentName.indexOf('/') === -1) {
    const value = files(routerPath)
    Vue.component('try' + componentName.replace(/([A-Z])/g, '-$1').toLowerCase(), value.default)
    // console.log('try' + componentName.replace(/([A-Z])/g, '-$1').toLowerCase());
  }
}, {});
import VueClipboard from 'vue-clipboard2';
Vue.use(VueClipboard)

//screenfull
import screenfull from "screenfull";

Vue.prototype.config = config;

// lodash挂载
Vue.prototype.$screenfull = screenfull;
Vue.prototype.resetSetItem = function (key, newVal) {
  //这个key就是我们要监听的那个key
  if (key === 'tws_id' || key === 'regionId') {
    // 创建一个StorageEvent事件
    var newStorageEvent = document.createEvent('StorageEvent');
    const storage = {
      setItem: function (k, val) {
        //这里可以灵活更改local和session
        sessionStorage.setItem(k, val);
        // 初始化创建的事件
        newStorageEvent.initStorageEvent('setItem', false, false, k, null, val, null, null);
        // 派发对象
        window.dispatchEvent(newStorageEvent)
      }
    }
    return storage.setItem(key, newVal);
  }
}
// 注册全局过滤器
Object.keys(filters).forEach((key) => {
  Vue.filter(key, filters[key]);
});

// 注册全局过指令
Object.keys(directives).forEach((key) => {
  Vue.use(directives[key]);
});

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");

/** 权限指令**/
Vue.directive('prikey', {
  inserted(el, binding) {
    //获取缓存的按钮数组
    const prilist = sessionStorage.getItem('prilist');
    const str = binding.value;
    var priarr = prilist.split(',');

    // 不存在则隐藏
    if (priarr === null || priarr === undefined || priarr.findIndex(item => item == str) === -1) {
      el.parentNode.removeChild(el);
      //el.style.display = 'none';
    }
  }
});


