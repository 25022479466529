/* 布局 */
//前台
import Layout from "@views/layout";

const userloginlogRouter = {
    path: "/business",
    component: Layout,
    redirect: {
        name: "BUSINESS"
    },
    children: [
        {
        path: "userloginlog/list",
        name: "USERLOGINLOG",
        meta: {
            title: "用户登录日志",
            keepAlive:true
        },
        component: () => import("@views/business/userloginlog/index.vue"),
    },
        ],
};

export default userloginlogRouter;
