<template>
    <div>
        <el-switch v-model="inputValue" :disabled="disabled" :width="width" />
    </div>
</template>

<script>
export default {
    name: "TrySwitch",
    data() {
        return {
            inputValue: this.value == 10 ? true : false,
        };
    },
    props: {
        // 绑定的值
        value: {
            type: [Number, String],
            default: 0
        },
        width: {//宽度
            type: Number,
            default: 40
        },
        disabled: {//禁用属性
            type: Boolean,
            default: false
        },
    },
    watch: {
        value: function (n) {
            this.inputValue = n == 10 ? true : false;
        },
        inputValue: function (n, o) {
            // 修改调用者传入的值
            if (n !== undefined && n != o) {
                console.log(n);
                this.$emit('input', n ? 10 : 20);
                this.$emit('change', n ? 10 : 20);
            }
        },
    }
};
</script>

<style></style>
