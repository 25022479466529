import API from "@utils/request";
import { PAGE_SIZE } from "@config";
import store from "../store";
const ADRESS = "/api/message/";

/**
 * 获取消息表列表（分页）
 * @author 四川创企
 * @DateTime 2024/01/19
 * @param {object} params
 * @param {object} pages
 */
export async function getMessageList(params, pages) {
  try {
    return await API.get(ADRESS + "list", {
      params: {
        ...params,
        ...pages,
      },
      headers: { isLoading: true },
    });
  } catch (error) {
    return error;
  }
}

/**
 * 获取消息表详情
 * @author 四川创企
 * @DateTime 2024/01/19
 * @param {number} id
 */
export async function getMessageInfo(id) {
  try {
    return await API.post(ADRESS + "info", {
      id: id,
    });
  } catch (error) {
    return error;
  }
}
/**
 * 编辑消息表
 * @author 四川创企
 * @DateTime 2024/01/19
 * @param {object} params
 */
export async function editMessage(params) {
  try {
    return await API.post(ADRESS + "edit", params);
  } catch (error) {
    return error;
  }
}

/**
 * 新增消息表
 * @author 四川创企
 * @DateTime 2024/01/19
 * @param {object} params
 */
export async function addMessage(params) {
  try {
    return await API.post(ADRESS + "add", params);
  } catch (error) {
    return error;
  }
}
/**
 * 刪除消息表
 * @author 四川创企
 * @DateTime 2024/01/19
 * @param {number} id
 */
export async function deleteMessage(id) {
  try {
    return await API.post(ADRESS + "delete", {
      id: {
        id,
      },
    });
  } catch (error) {
    return error;
  }
}
/**
 * 修改单个字段值
 * @author 四川创企
 * @DateTime 2024/01/19
 * @param {number} id
 */
export async function updateMessageField(id, field, value) {
  try {
    return await API.post(ADRESS + "updatefield", {
      id: id,
      field: field,
      value: value,
    });
  } catch (error) {
    return error;
  }
}
/**
 * 获取消息表键值对
 * @author 四川创企
 * @DateTime 2024/01/19
 * @param {object} key
 * @param {object} value
 */
export async function getMessageKeyVal(key, value, params = {}) {
  try {
    return await API.post(ADRESS + "getkeyval", {
      key: key,
      value: value,
      params: params,
    });
  } catch (error) {
    return error;
  }
}

/**
 * 获取消息未读数量
 * @author 四川创企
 * @DateTime 2024/01/19
 * @param {object} key
 * @param {object} value
 */
export async function getMesNoReadNum(params = {}) {
    try {
      let res = await API.post(ADRESS + "getmesnoreadnum",  params,
      );
      if (res.status == 200) {
        store.state.messagedata = res.data;
      }
      return res;
    } catch (error) {
      return error;
    }
  }

/**
 * 获取长连接状态
 * @author 四川创企
 * @DateTime 2024/01/19
 */
export async function getSocketStatus() {
    try {
      let res = await API.post(ADRESS + "getsocketstatus",
      );      
      return res;
    } catch (error) {
      return error;
    }
  }
  
