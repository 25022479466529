/* 布局 */
//前台
import Layout from "@views/layout";

const cooperatebusinessarticleRouter = {
    path: "/business",
    component: Layout,
    redirect: {
        name: "BUSINESS"
    },
    children: [
        {
            path: "cooperatebusinessarticle",
            name: "COOPERATEBUSINESSARTICLE",
            meta: {
                title: "合作企业文档管理",
                sort: 1,
            },
            component: () => import("@views/business/cooperatebusinessarticle/index.vue"),
            redirect: {
                name: "COOPERATEBUSINESSARTICLE_LIST",
            },
            children: [
                // {
                //     path: "list",
                //     name: "COOPERATEBUSINESSARTICLE_LIST",
                //     meta: {
                //         title: "合作企业文档管理",
                //         sort: 2,
                //         keepAlive: true
                //     },
                //     component: () => import("@views/business/cooperatebusinessarticle/list.vue"),
                // },
                // {
                //     path: "add",
                //     name: "COOPERATEBUSINESSARTICLE_ADD",
                //     meta: {
                //         title: "新增合作-企业-文档",
                //         parentPath: '/business/cooperatebusinessarticle/list',
                //         operate: true,
                //     },
                //     component: () => import("@views/business/cooperatebusinessarticle/form.vue"),
                // },
                // {
                //     path: "edit",
                //     name: "COOPERATEBUSINESSARTICLE_EDIT",
                //     meta: {
                //         title: "编辑合作-企业-文档",
                //         parentPath: '/business/cooperatebusinessarticle/list',
                //         operate: true,
                //     },
                //     component: () => import("@views/business/cooperatebusinessarticle/form.vue"),
                // },


                // {
                //     path: "listans",
                //     name: "COOPERATEBUSINESSARTICLEANS_LIST",
                //     meta: {
                //         title: "文档回复管理",
                //         parentPath: '/business/cooperatebusinessarticle/list',
                //         operate: true,
                //     },
                //     component: () => import("@views/business/cooperatebusinessarticleans/list.vue"),
                // },
                // {
                //     path: "addans",
                //     name: "COOPERATEBUSINESSARTICLEANS_ADD",
                //     meta: {
                //         title: "新增文档回复",
                //         parentPath: '/business/cooperatebusinessarticle/list',
                //         operate: true,
                //     },
                //     component: () => import("@views/business/cooperatebusinessarticleans/form.vue"),
                // },
                // {
                //     path: "editans",
                //     name: "COOPERATEBUSINESSARTICLEANS_EDIT",
                //     meta: {
                //         title: "编辑文档回复",
                //         parentPath: '/business/cooperatebusinessarticle/list',
                //         operate: true,
                //     },
                //     component: () => import("@views/business/cooperatebusinessarticleans/form.vue"),
                // },
            ],
        },


    ],
};

export default cooperatebusinessarticleRouter;
