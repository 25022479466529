/* 布局 */
//前台
import Layout from "@views/layout";

const websitesettingRouter = {
    path: "/business",
    component: Layout,
    redirect: {
        name: "BUSINESS"
    },
    children: [
            {
            path: "websitesetting",
            name: "WEBSITESETTING",
            meta: {
                title: "网站设置管理",
                sort: 1,
            },
            component: () => import("@views/business/websitesetting/index.vue"),
            redirect: {
                name: "WEBSITESETTING_LIST",
            },
            children: [
                {
                    path: "list",
                    name: "WEBSITESETTING_LIST",
                    meta: {
                        title: "网站设置管理",
                        sort: 2,
                        keepAlive:true
                    },
                    component: () => import("@views/business/websitesetting/list.vue"),
                },
                                {
                    path: "add",
                    name: "WEBSITESETTING_ADD",
                    meta: {
                        title: "新增网站设置表",
                        parentPath: '/business/websitesetting/list',
                        operate: true,
                    },
                    component: () => import("@views/business/websitesetting/form.vue"),
                },
                                                {
                    path: "edit",
                    name: "WEBSITESETTING_EDIT",
                    meta: {
                        title: "编辑网站设置表",
                        parentPath: '/business/websitesetting/list',
                        operate: true,
                    },
                    component: () => import("@views/business/websitesetting/form.vue"),
                },
                                        ],
    },


        ],
};

export default websitesettingRouter;
