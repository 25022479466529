/* 布局 */
//前台
import Layout from "@views/layout";

const cooperatebusinessarticleanstipsRouter = {
    path: "/business",
    component: Layout,
    redirect: {
        name: "BUSINESS"
    },
    children: [
        ],
};

export default cooperatebusinessarticleanstipsRouter;
