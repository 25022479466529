/* 布局 */
//前台
import Layout from "@views/layout";

const raffleeventrecordRouter = {
    path: "/business",
    component: Layout,
    redirect: {
        name: "BUSINESS"
    },
    children: [
            {
            path: "raffleeventrecord",
            name: "RAFFLEEVENTRECORD",
            meta: {
                title: "参与记录",
                sort: 1,
            },
            component: () => import("@views/business/raffleeventrecord/index.vue"),
            redirect: {
                name: "RAFFLEEVENTRECORD_LIST",
            },
            children: [
                {
                    path: "list",
                    name: "RAFFLEEVENTRECORD_LIST",
                    meta: {
                        title: "参与记录",
                        sort: 2,
                        keepAlive:true
                    },
                    component: () => import("@views/business/raffleeventrecord/list.vue"),
                },
                                {
                    path: "add",
                    name: "RAFFLEEVENTRECORD_ADD",
                    meta: {
                        title: "新增抽奖记录表",
                        parentPath: '/business/raffleeventrecord/list',
                        operate: true,
                    },
                    component: () => import("@views/business/raffleeventrecord/form.vue"),
                },
                                                {
                    path: "edit",
                    name: "RAFFLEEVENTRECORD_EDIT",
                    meta: {
                        title: "编辑抽奖记录表",
                        parentPath: '/business/raffleeventrecord/list',
                        operate: true,
                    },
                    component: () => import("@views/business/raffleeventrecord/form.vue"),
                },
                                        ],
    },


        ],
};

export default raffleeventrecordRouter;
