import { getMesNoReadNum } from "@api/Message";
// 建立配置本地存储
const state = {
    messagedata: {
        msgNoReadNum:0,
        messageList:[]
    }, //消息集合
};
const mutations = {
    messagedata: (state, obj) => {
        state.messagedata = obj;
    },
    addmessagetodata: (state, newMessage) => {       
        state.messageList.unshift(newMessage);    
        if (state.msgNoReadNum === undefined) {
            state.msgNoReadNum = 1;
        } else {
            state.msgNoReadNum++; // 增加未读消息计数
        }
    },
};


const actions = {
    async setMessagedata({ commit }) {
    await getMesNoReadNum();
    },
    };

export default {
    namespaced: true,
    state,
    mutations,
    actions,
};