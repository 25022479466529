/* 布局 */
//前台
import Layout from "@views/layout";

const raffleeventRouter = {
    path: "/business",
    component: Layout,
    redirect: {
        name: "BUSINESS"
    },
    children: [
        {
            path: "raffleevent",
            name: "RAFFLEEVENT",
            meta: {
                title: "抽奖活动",
                sort: 1,
            },
            component: () => import("@views/business/raffleevent/index.vue"),
            redirect: {
                name: "RAFFLEEVENT_LIST",
            },
            children: [
                {
                    path: "list",
                    name: "RAFFLEEVENT_LIST",
                    meta: {
                        title: "抽奖活动",
                        sort: 2,
                        keepAlive: true
                    },
                    component: () => import("@views/business/raffleevent/list.vue"),
                },
                {
                    path: "add",
                    name: "RAFFLEEVENT_ADD",
                    meta: {
                        title: "新增抽奖活动表",
                        parentPath: '/business/raffleevent/list',
                        operate: true,
                    },
                    component: () => import("@views/business/raffleevent/form.vue"),
                },
                {
                    path: "edit",
                    name: "RAFFLEEVENT_EDIT",
                    meta: {
                        title: "编辑抽奖活动表",
                        parentPath: '/business/raffleevent/list',
                        operate: true,
                    },
                    component: () => import("@views/business/raffleevent/form.vue"),
                },
                {
                    path: "raffleeventrecord",
                    name: "RAFFLEEVENTRECORD",
                    meta: {
                        title: "参与记录",
                        parentPath: '/business/raffleevent/list',
                        operate: true,
                    },
                    component: () => import("@views/business/raffleeventrecord/list.vue"),
                },
            ],
        },


    ],
};

export default raffleeventRouter;
