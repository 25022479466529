<template>
  <div>
    <div
      v-if="dataType == 'SINGLE'"
      v-for="item in optionsData"
      :key="item[valueKey]"
    >
      <el-tag v-if="item[valueKey] == tValue">{{ item[labelKey] }}</el-tag>
    </div>

    <div
      v-if="dataType == 'ARR'"
      v-for="item in optionsData"
      :key="item[valueKey]"
      style="margin: 0 auto"
    >
      <p
        v-for="sitem in trimSpace(tValue)"
        :key="sitem"
        style="width: auto; float: left; margin-left: 5px"
      >
        <el-tag v-if="item[valueKey] == sitem">{{ item[labelKey] }}</el-tag>
      </p>
    </div>
  </div>
</template>
<script>
import API from "@utils/request";
import { getDictionaryKeyVal } from "@/api/DataDictionary";
import { getDataDicttreeListKeyVal } from "@/api/DataDicttree";
import { Empty } from "element-ui";
export default {
  name: "TryDict",
  props: {
    valueKey: {
      // 列表中选项的值对应的key
      type: [String],
      default: "value",
    },
    labelKey: {
      // 列表中选项的标签对应的key
      type: String,
      default: "label",
    },
    dictName: {
      // 字典值
      type: String,
      default: "",
    },
    value: {
      // 绑定的值
      type: [Number, String],
      default: undefined,
    },

    size: {
      // medium/small/mini
      type: String,
      default: "medium",
    },

    options: {
      //数据源
      type: [Array, Object],
      default: undefined,
    },

    dataType: {
      //传入数据源类型
      type: String,
      default: "SINGLE",
    },
    url: {
      // 接口地址
      type: String,
      default: undefined,
    },
    parameData: {
      //Url带入参数
      type: [String, Number, Array],
      default: undefined,
    },
    isTreeData: {
      // 是否为DataTree数据源
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading: false,
      tValue:
        this.dataType == "ARR"
          ? this.trimSpace(
              this.value == undefined || this.value == null
                ? ""
                : this.value.split(",")
            )
          : this.value,
      optionsData: this.options,
    };
  },
  watch: {
    value(n, o) {
      if (n) {
        this.tValue = this.dataType == "ARR" ? this.trimSpace(n.split(",")) : n;
      }
    },
    options(n, o) {
      this.optionsData = n;
    },
  },
  created() {
    this.requestData();
  },
  methods: {
    // 请求数据
    async requestData() {
      if (this.dictName) {
        this.loading = true;
        if (this.isTreeData) {
          let { data: res } = await getDataDicttreeListKeyVal(this.dictName);

          this.optionsData = res;
        } else {
          let { data: res } = await getDictionaryKeyVal(this.dictName);
          this.optionsData = res;
        }
        this.loading = false;
      } else if (this.url) {
        try {
          let { data: res } = await API.post(
            this.url,
            eval("(" + this.parameData + ")")
          );
          this.optionsData = res;
        } catch (error) {
          console.log(error);
          return error;
        }
      }
    },
    // 子组件值变化要通过父组件
    handleChange(value) {
      this.$emit("input", value);
      this.$emit("change", value);
    },
    /**
     * 从数组中移除空值
     * @array {Array}
     */
    trimSpace(array) {
      for (var i = 0; i < array.length; i++) {
        //这里为过滤的值
        if (
          array[i] == "" ||
          array[i] == null ||
          typeof array[i] == "undefined" ||
          array[i] == ""
        ) {
          array.splice(i, 1);
          i = i - 1;
        }
      }
      return array;
    },
  },
};
</script>
