/* 布局 */
//前台
import Layout from "@views/layout";

const userrecruitRouter = {
    path: "/business",
    component: Layout,
    redirect: {
        name: "BUSINESS"
    },
    children: [
            {
            path: "userrecruit",
            name: "USERRECRUIT",
            meta: {
                title: "会员招募",
                sort: 1,
            },
            component: () => import("@views/business/userrecruit/index.vue"),
            redirect: {
                name: "USERRECRUIT_LIST",
            },
            children: [
                {
                    path: "list",
                    name: "USERRECRUIT_LIST",
                    meta: {
                        title: "会员招募",
                        sort: 2,
                        keepAlive:true
                    },
                    component: () => import("@views/business/userrecruit/list.vue"),
                },
                                {
                    path: "add",
                    name: "USERRECRUIT_ADD",
                    meta: {
                        title: "新增会员招募",
                        parentPath: '/business/userrecruit/list',
                        operate: true,
                    },
                    component: () => import("@views/business/userrecruit/form.vue"),
                },
                                                {
                    path: "edit",
                    name: "USERRECRUIT_EDIT",
                    meta: {
                        title: "编辑会员招募",
                        parentPath: '/business/userrecruit/list',
                        operate: true,
                    },
                    component: () => import("@views/business/userrecruit/form.vue"),
                },
                                        ],
    },


        ],
};

export default userrecruitRouter;
