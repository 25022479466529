/* 布局 */
//前台
import Layout from "@views/layout";

const postansRouter = {
    path: "/business",
    component: Layout,
    redirect: {
        name: "BUSINESS"
    },
    children: [
            {
            path: "postans",
            name: "POSTANS",
            meta: {
                title: "帖子回复管理",
                sort: 1,
            },
            component: () => import("@views/business/postans/index.vue"),
            redirect: {
                name: "POSTANS_LIST",
            },
            children: [
                {
                    path: "list",
                    name: "POSTANS_LIST",
                    meta: {
                        title: "帖子回复管理",
                        sort: 2,
                        keepAlive:true
                    },
                    component: () => import("@views/business/postans/list.vue"),
                },
                                {
                    path: "add",
                    name: "POSTANS_ADD",
                    meta: {
                        title: "新增帖子回复表",
                        parentPath: '/business/postans/list',
                        operate: true,
                    },
                    component: () => import("@views/business/postans/form.vue"),
                },
                                                {
                    path: "edit",
                    name: "POSTANS_EDIT",
                    meta: {
                        title: "编辑帖子回复表",
                        parentPath: '/business/postans/list',
                        operate: true,
                    },
                    component: () => import("@views/business/postans/form.vue"),
                },
                                        ],
    },


        ],
};

export default postansRouter;
