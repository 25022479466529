/* 布局 */
//前台
import Layout from "@views/layout";

const sysemailRouter = {
    path: "/business",
    component: Layout,
    redirect: {
        name: "BUSINESS"
    },
    children: [
            {
            path: "sysemail",
            name: "SYSEMAIL",
            meta: {
                title: "邮件发送日志",
                sort: 1,
            },
            component: () => import("@views/business/sysemail/index.vue"),
            redirect: {
                name: "SYSEMAIL_LIST",
            },
            children: [
                {
                    path: "list",
                    name: "SYSEMAIL_LIST",
                    meta: {
                        title: "邮件发送日志",
                        sort: 2,
                        keepAlive:true
                    },
                    component: () => import("@views/business/sysemail/list.vue"),
                },
                                                        ],
    },


        ],
};

export default sysemailRouter;
