import API from "@utils/request";
import { PAGE_SIZE } from "@config";
import store from "../store";
const ADRESS = "/api/datadicttree/";

/**
* 获取树形字典列表（分页）
* @author 四川创企
* @DateTime 2023/08/14
* @param {object} params
* @param {object} pages
*/
export async function getDataDicttreeList(params, pages) {
    try {
        return await API.get(ADRESS + "list", {
            params: {
                ...params,
                ...pages,
            },
            headers: { isLoading: true },
        });
    } catch (error) {
        return error;
    }
}

/**
* 获取树形字典详情
* @author 四川创企
* @DateTime 2023/08/14
* @param {number} id
*/
export async function getDataDicttreeInfo(id) {
    try {
        return await API.post(ADRESS + "info", {
            id: {
                id,
            },
        });
    } catch (error) {
        return error;
    }
}
/**
* 编辑树形字典
* @author 四川创企
* @DateTime 2023/08/14
* @param {object} params
*/
export async function editDataDicttree(params) {
    try {
        return await API.post(ADRESS + "edit", params);
    } catch (error) {
        return error;
    }
}

/**
* 新增树形字典
* @author 四川创企
* @DateTime 2023/08/14
* @param {object} params
*/
export async function addDataDicttree(params) {
    try {
        return await API.post(ADRESS + "add", params);
    } catch (error) {
        return error;
    }
}
/**
* 刪除树形字典
* @author 四川创企
* @DateTime 2023/08/14
* @param {number} id
*/
export async function deleteDataDicttree(id) {
    try {
        return await API.post(ADRESS + "delete", {
            id: {
                id,
            },
        });
    } catch (error) {
        return error;
    }
}
/**
* 修改单个字段值
* @author 四川创企
* @DateTime 2023/08/14
* @param {number} id
*/
export async function updateDataDicttreeField(id, field, value) {
    try {
        return await API.post(ADRESS + "updatefield", {
            id: id,
            field: field,
            value: value,
        });
    } catch (error) {
        return error;
    }
}
/**
* 获取树形字典键值对
* @author 四川创企
* @DateTime 2023/08/14
* @param {String} parentCode
*/
export async function getDataDicttreeKeyVal(topCode) {
    try {
       
        if (Object.keys(store.state.dicttree.dicttree).length != 0 && store.state.dicttree.dicttree[topCode]) {
            var rdata = JSON.stringify(store.state.dicttree.dicttree[topCode]);
            rdata = JSON.parse(rdata);
            return {
                data: rdata,
                msg: "操作成功",
                status: 200
            }
        } else {
            let res = await API.post(ADRESS + "getkeyval", { topCode: topCode });
            if (store.state.dicttree.dicttree[topCode] == undefined) {
                store.state.dicttree.dicttree[topCode] = res.data;
            }
            return res;
        }

    } catch (error) {
        return error;
    }
}

/**
* 获取非树形字典键值对
* @author 四川创企
* @DateTime 2023/08/14
* @param {String} parentCode
*/
export async function getDataDicttreeListKeyVal(topCode) {
    try {
        if (Object.keys(store.state.dicttree.dicttree).length != 0 && store.state.dicttree.dicttree["L-" + topCode]) {
            return {
                data: store.state.dicttree.dicttree["L-" + topCode],
                msg: "操作成功",
                status: 200
            }
        } else {
            let res = await API.post(ADRESS + "getlistkeyVal", { topCode: topCode });
            if (store.state.dicttree.dicttree["L-" + topCode] == undefined) {
                store.state.dicttree.dicttree["L-" + topCode] = res.data;
            }
            return res;
        }
    } catch (error) {
        return error;
    }
}

function treeToList(list) {
    let res = [];
    for (const item of list) {
        const { children, ...i } = item;

        if (children) {
            res = res.concat(this.treeToList(children));
        }
        res.push(i);
    }
    return res;
}


/**
 * 生成字典缓存文件
 */
export async function createDataDicttreeCash(isCash=false) {
    try {
       
        return await API.post(ADRESS + "createcash", {isCash:isCash});
       
    } catch (error) {
        return error;
    }
}

/**
* 获取一级字典键值对
*/
export async function getTopDictKeyVal() {
    try {
        return await API.post(ADRESS + "gettopdictkeyVal", {});
    } catch (error) {
        return error;
    }
}

/**
 * 获取树形区域分配列表
*/
export async function getTreeArea(area_tws_id) {
  try {
    return await API.post(ADRESS + "getTreeArea", 
	{area_tws_id: area_tws_id },
	{	
		headers: { isLoading: true },
    });
	  
  } catch (error) {
    return error;
  }
}

/**
 * 获取用户授权区域ID清单
*/
export async function getRoleAreaId(roleid, issuper, area_tws_id) {
  try {
    return await API.post(ADRESS + "getroleareaid", { roleid: roleid, issuper: issuper , area_tws_id: area_tws_id }, {
      headers: { isLoading: true },
    });
  } catch (error) {
    return error;
  }
}

/**
 * 保存角色清单
*/
export async function saveRoleArea(roleid, areaarr, area_tws_id) {
  try {
    return await API.post(ADRESS + "saverolearea",
      { roleid: roleid, areaarr: areaarr, area_tws_id: area_tws_id },
      {
        headers: { isLoading: true },
      });
  } catch (error) {
    return error;
  }
}

/**
* 下载导入模板树形地区  * @author 四川创企
* @DateTime 2024/06/19
*/
export async function importTemplate() {
    try {
        return await API.post(ADRESS + "importTemplate", {});
    } catch (error) {
        return error;
    }
}
/**
* 导入树形地区  * @author 四川创企
* @DateTime 2024/06/19
* @param {object} importfile
*/
export async function importDic(importfile) {
    try {
        return await API.post(ADRESS + "import", { importfile: importfile, });
    } catch (error) {
        return error;
    }
}


/**
* 获取树形字典列表（分页）
* @author 四川创企
* @DateTime 2023/08/14
* @param {object} params
* @param {object} pages
*/
export async function getLazyLevelList(params, pages) {
    try {
        return await API.get(ADRESS + "getLazyLevelList", {
            params: {
                ...params,
                ...pages,
            },
            headers: { isLoading: true },
        });
    } catch (error) {
        return error;
    }
}