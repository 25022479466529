/* 布局 */
//前台
import Layout from "@views/layout";

const activitycategoryRouter = {
    path: "/business",
    component: Layout,
    redirect: {
        name: "BUSINESS"
    },
    children: [
            {
            path: "activitycategory",
            name: "ACTIVITYCATEGORY",
            meta: {
                title: "活动分类管理",
                sort: 1,
            },
            component: () => import("@views/business/activitycategory/index.vue"),
            redirect: {
                name: "ACTIVITYCATEGORY_LIST",
            },
            children: [
                {
                    path: "list",
                    name: "ACTIVITYCATEGORY_LIST",
                    meta: {
                        title: "活动分类管理",
                        sort: 2,
                        keepAlive:true
                    },
                    component: () => import("@views/business/activitycategory/list.vue"),
                },
                                {
                    path: "add",
                    name: "ACTIVITYCATEGORY_ADD",
                    meta: {
                        title: "新增活动分类",
                        parentPath: '/business/activitycategory/list',
                        operate: true,
                    },
                    component: () => import("@views/business/activitycategory/form.vue"),
                },
                                                {
                    path: "edit",
                    name: "ACTIVITYCATEGORY_EDIT",
                    meta: {
                        title: "编辑活动分类",
                        parentPath: '/business/activitycategory/list',
                        operate: true,
                    },
                    component: () => import("@views/business/activitycategory/form.vue"),
                },
                                        ],
    },


        ],
};

export default activitycategoryRouter;
