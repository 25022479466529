import API from "@utils/request";

const COMMON = "/api/common/";

/**
 * 文件上传
 * @param {file} file
 */
export async function uploadFile(file) {
  try {
    const formData = new FormData();
    formData.append("file", file, file.name);

    return await API.post(COMMON + "/uploadfile", formData, {
      transformRequest: [
        function (data) {
          return data;
        },
      ],
    });
  } catch (error) {
    return error;
  }
}



/**
 * 获取阿里云OSS STS相关值
 * @returns 
 */
export async function getaliyunoss() {
  try {
    return await API.post("/api/tool/getaliyunoss");
  } catch (error) {
    return error;
  }
}
