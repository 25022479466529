import API from "@utils/request";
import { PAGE_SIZE } from "@config";
import store from "../store";
const ADRESS = "/api/websitesetting/";

/**
* 获取网站设置表列表（分页）
* @author 四川创企
* @DateTime 2024/05/22
* @param {object} params
* @param {object} pages
*/
export async function getWebsiteSettingList(params, pages) {
    try {
        return await API.get(ADRESS + "list", {
            params: {
            ...params,
            ...pages,
            },
            headers: { isLoading: true },
        });
    } catch (error) {
        return error;
    }
}

  /**
  * 获取网站设置表详情
  * @author 四川创企
  * @DateTime 2024/05/22
  * @param {number} id
  */
  export async function getWebsiteSettingInfo(id) {
  try {
  return await API.post(ADRESS + "info", {
  id:id,
  });
  } catch (error) {
  return error;
  }
  }
  /**
  * 编辑网站设置表
  * @author 四川创企
  * @DateTime 2024/05/22
  * @param {object} params
  */
  export async function editWebsiteSetting(params) {
  try {
  return await API.post(ADRESS + "edit", params);
  } catch (error) {
  return error;
  }
  }

  /**
  * 新增网站设置表
  * @author 四川创企
  * @DateTime 2024/05/22
  * @param {object} params
  */
  export async function addWebsiteSetting(params) {
  try {
  return await API.post(ADRESS + "add", params);
  } catch (error) {
  return error;
  }
  }
  /**
  * 刪除网站设置表
  * @author 四川创企
  * @DateTime 2024/05/22
  * @param {number} id
  */
  export async function deleteWebsiteSetting(id) {
  try {
  return await API.post(ADRESS + "delete", {
  id: {
  id,
  },
  });
  } catch (error) {
  return error;
  }
  }
  
  /**
  * 获取站点表键值对
  * @author 四川创企
  * @DateTime 2024/05/22
  * @param {object} key
  * @param {object} value
  */
  export async function getWebsiteSettingKeyVal(key, value,params={}) {
  try {
  return await API.post(ADRESS + "getWebKeyVal", {
  key: key,
  value: value,
  params:params
  });
  } catch (error) {
  return error;
  }
  }
                                                      

