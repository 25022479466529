<template>
  <div class="demo-image__preview">
    <div class="demo-image-box" v-if="tValue.length <= 1">
      <el-image v-for="item in tValue" :key="item" :src="item" :preview-src-list="tValue">
      </el-image>
    </div>
    <div class="demo-image-box mid-list" v-else-if="tValue.length <= 3">
      <el-image v-for="item in tValue" :key="item" :src="item" :preview-src-list="tValue">
      </el-image>
    </div>
    <div class="demo-image-box max-list" v-else-if="tValue.length > 3">
      <el-image v-for="item in tValue" :key="item" :src="item" :preview-src-list="tValue">
      </el-image>
    </div>

  </div>
</template>
<script>
import { Empty } from 'element-ui'
export default {
  name: 'TryImg',
  props: {
    value: {// 绑定的值
      type: String,
      default: undefined
    },

  },
  data() {
    return {
      loading: false,
      tValue: [],
    }
  },
  watch: {
    value(n, o) {
      if (n) {
        let imgs = this.value ? JSON.parse(this.value) : [];
        let that = this;
        that.tValue = [];
        imgs.forEach(function (item) {
          that.tValue.push(item.att_url);
        });
      } else if(n == null){
        this.tValue = [];
      }

    },
  },
  created() {
    let imgs = this.value ? JSON.parse(this.value) : [];
    let that = this;
    that.tValue = [];
    imgs.forEach(function (item) {
      that.tValue.push(item.att_url);
    });
  },
  methods: {
    /**
    * 从数组中移除空值
    * @array {Array} 
    */
    trimSpace(array) {
      for (var i = 0; i < array.length; i++) {
        //这里为过滤的值
        if (array[i] == "" || array[i] == null || typeof (array[i]) == "undefined" || array[i] == '') {
          array.splice(i, 1);
          i = i - 1;
        }
      }
      return array;
    }
  }
}
</script>
<style lang="scss" scoped>
.demo-image__preview {
  display: flex;
  justify-content: space-around;

  .demo-image-box {
    width: 100%;
    box-sizing: border-box;
    display: flex;
    justify-content: space-around;

    .el-image {
      width: 100px;
      height: 100px;
      align-self: center;
    }
  }

  .mid-list {
    display: flex;
    justify-content: space-around;

    .el-image {
      width: 65px;
      height: 65px;
      align-self: center;
    }
  }

  .max-list {
    display: flex;
    justify-content: space-around;

    .el-image {
      width: 50px;
      height: 50px;
      align-self: center;
    }
  }
}
</style>
